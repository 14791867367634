import React, { useState } from "react"
import Footer from "../../components/footer"
import { graphql, Link, navigate } from "gatsby"
import { ProductNav } from "../../components/header/Header"
import "../../styles/solution/china.less"
import SEO from "../../components/SEO"
import { Button, Tab } from "../../components/ui"
import { GetStarted } from "../index"
import { Solution, Feedback } from "../products/bare-metal-cloud"
import Drop from "../../components/drop"
// import ResourceMap from "../../components/map"
import { Map } from "./india"
import { SolutionTopBanner, ProductBlog } from "../../components/block"
const loadText = require("src/utils").loadText
const static_data = loadText("china-digital-presence")
const Number = () => (
  <div className="china-number">
    <div className="china-number-container">
      <div className="page-middle china-number-content">
        <div className="font-36 semi-bold">{static_data.byTheNumber.title}</div>
        <div className="china-number-text font-20 mt-24">
          {static_data.byTheNumber.description}
        </div>
        <div className="mt-32">
          <Button
            width={391}
            style={{ color: "#24B7FF", background: "#fff" }}
            onClick={() => {
              window.open("/by-the-numbers/china")
            }}
          >
            {static_data.byTheNumber.buttonText}
          </Button>
        </div>
      </div>
    </div>
  </div>
)
export const Overview = ({ data, bgPic = undefined }) => {
  const Item = ({
    img,
    title,
    content,
    icon,
  }: {
    img: string
    title: string
    content: string
    icon: string
    bgPic?: string
  }) => {
    return (
      <div className="china-overview-item clearfix block-middle">
        <div className="imgContainer">
          <img src={img} />
        </div>
        <div className={`china-overview-itemContent textContainer`}>
          <img src={icon} alt="" className="china-overview-icon" />
          <div className="font-36 semi-bold t-color mt-24">{title}</div>
          <div className="mt-24 font-20" style={{ color: "#616E82" }}>
            {content}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className="china-overview full-bg"
      style={bgPic ? { backgroundImage: `url("${bgPic}")` } : {}}
    >
      <div className="font-36 semi-bold t-color text-center">
        {static_data.overview.title}
      </div>
      <div className="page-middle">
        <div className="china-overview-content">
          {data.map(({ title, content, img, icon }) => (
            <Item title={title} content={content} img={img} icon={icon} />
          ))}
        </div>
      </div>
    </div>
  )
}
const Differences = () => (
  <div className="china-difference full-bg">
    <div className="page-middle" style={{ overflow: "hidden" }}>
      <div className="china-difference-bg"></div>
      <div className="font-36 semi-bold t-color block-middle mobile-padding">
        {static_data.differences.title}
      </div>
      <div
        className="font-20 mt-24 block-middle mobile-padding"
        style={{ maxWidth: "1016px" }}
        dangerouslySetInnerHTML={{
          __html: static_data.differences.description,
        }}
      />
      <div className="china-difference-content page-middle ">
        <div className="china-difference-content-bg"></div>
        {static_data.differences.content.map(({ title, description }, i) => (
          <div
            className={`china-difference-contentItem mobile-padding china-difference-contentItem-${i}`}
          >
            <div className="left">
              <img
                src={`/solution/china/difference-${i + 1}.png`}
                alt={`difference-${i + 1}`}
              />
            </div>
            <div className="right">
              <div className="right-title-bg">
                <div className="font-36 semi-bold t-color">{title}</div>
              </div>
              <div
                className="font-20 mt-24"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)
const MiddleBanner = () => (
  <div className="china-middleBanner">
    <Drop color="#fff" bgColor="rgb(63,193,255)" />
    <div className="china-middleBanner-content">
      <div className="page-middle">
        <div style={{ maxWidth: "624px" }}>
          <div className="font-36 semi-bold">
            {static_data.middleBanner.title}
          </div>
          <div className="font-20 mt-24">
            {static_data.middleBanner.description}
          </div>
        </div>
        <img
          src="/solution/china/middleBanner-people.png"
          alt={static_data.middleBanner.title}
          className="middleBannerPeople"
        />
        <img
          src="/solution/china/middleBanner-people-m.png"
          alt={static_data.middleBanner.title}
          className="middleBannerPeopleMobile"
        />
      </div>
    </div>
  </div>
)
// const Map = () => {
//   return (
//     <div className="china-map">
//       <div className="font-36 semi-bold t-color">{static_data.map.title}</div>
//       <div className="font-20 mt-24 block-middle" style={{ maxWidth: "981px" }}>
//         {static_data.map.description}
//       </div>
//       <div className="mt-56">
//         <ResourceMap />
//       </div>
//     </div>
//   )
// }
const CompleteSolutions = () => {
  const Item = ({ title, desc, img }) => (
    <div className="completeSolution-item">
      <img src={img} alt={title} />
      <div className="font-20 semi-bold t-color mt-16">{title}</div>
      <div className="completeSolution-item-desc">{desc}</div>
    </div>
  )
  const ItemMobile = ({ title, desc, img }) => (
    <div className="completeSolution-item-mobile">
      <img src={img} alt={title} />
      <div>
        <div className="font-20 semi-bold t-color mt-16">{title}</div>
        <div className="completeSolution-item-desc">{desc}</div>
      </div>
    </div>
  )
  return (
    <div className="completeSolution">
      <div className="semi-bold font-36 text-center" style={{ color: "#fff" }}>
        {static_data.completeSolution.title}
      </div>
      <div className="completeSolution-container mt-56">
        {static_data.completeSolution.content.map(
          ({ name, description }, idx) => (
            <Item
              title={name}
              desc={description}
              img={`/solution/china/completeSolution-${idx + 1}.png`}
            />
          )
        )}
        {static_data.completeSolution.content.map(
          ({ name, description }, idx) => (
            <ItemMobile
              title={name}
              desc={description}
              img={`/solution/china/completeSolution-${idx + 1}-m.png`}
            />
          )
        )}
      </div>
    </div>
  )
}
const Partner = () => (
  <div className="china-partner">
    <div className="china-partner-content full-bg">
      <div className="font-36 semi-bold">{static_data.partner.title}</div>
    </div>
    <div className="page-middle" style={{ position: "relative" }}>
      <div className="china-partner-logo ">
        <img
          src="/solution/china/partner-logo.svg"
          alt={static_data.partner.title}
        />
      </div>
      <div className="china-partner-logo-m ">
        <img
          src="/solution/china/partner-logo-m.png"
          alt={static_data.partner.title}
        />
      </div>
    </div>
  </div>
)
export default function Home({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  return (
    <div className="china">
      <SEO {...static_data.seo} featuredImage="/featuredImage/china.jpg" />
      <SolutionTopBanner
        {...static_data.topBanner}
        backgroundAnimated={true}
        bgColor="linear-gradient(180deg,#F5F6FF 0%,#E7EAFF 100%)"
        bgPic={"/solution/china/topBanner-bg.svg"}
        source="china"
        pic={{
          src: "/solution/china/topBanner-pic.png",
          style: {
            right: 0,
            bottom: "50px",
            // top: 0,
            position: "absolute",
          },
        }}
        bannerPic={{
          src: "/solution/china/topBanner-bg-m.svg",
          style: {
            backgroundPosition: "top center",
          },
        }}
      />
      <ProductNav
        name={static_data.topBanner.title}
        menus={static_data.navigation}
        minWidth={1100}
      />
      <a id="marketOverview" />
      <Overview
        bgPic="/solution/china/market-bg.svg"
        data={static_data.overview.content.map((i, idx) => ({
          ...i,
          img: `/solution/china/solution-${idx + 1}.png`,
          icon: `/solution/china/overview-icon-${idx + 1}.svg`,
        }))}
      />
      <Number />
      <a id="difference" />

      <Differences />
      <MiddleBanner />
      <Feedback {...strapiTestimonials} />
      <Partner />
      <Map
        initPos={{
          zoom: 4,
          latlng: [38, 110.407394],
        }}
        cities={static_data.cities}
        title={static_data.map.title}
        description={static_data.map.description}
      />
      <a id="solution" />
      <CompleteSolutions />
      <Solution {...static_data.solution} />
      <a id="learnMore" />
      <ProductBlog
        title={static_data.blog.title}
        // bgColor="#fff"
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <GetStarted source="china" />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "China" } } }
        author: {}
      }
    ) {
      edges {
        node {
          slug
          wp_date
          content
          categories {
            name
          }
          title
          created_at
          thumbnail {
            localFile {
              publicURL
            }
          }
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "china" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
